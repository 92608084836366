

























































































































































































































































































































import { defineComponent, ref, onMounted, watch, Ref } from '@vue/composition-api'
import {
  StepButton,
  AddButton,
  LoaderComponent,
  CustomerDialog,
  PreviewInvoiceDialog,
  CommentDialog
} from 'components'
import { moment, api, framebus } from 'plugins'
import {
  convertToWidthCharacter,
  endpoints,
  frameBusEvent,
  query,
  showError,
  toCamelCase,
  urlPath
} from 'utils'
import { Customer as CustomerRaw, Item, Variety } from 'typings'
import { debounce } from 'lodash'
import ConfirmZipOrPdfDialog from './ConfirmZipOrPdfDialog.vue'
import ConfirmSendInvoice from './ConfirmSendInvoice.vue'
import EditSendTos from './EditSendTos.vue'

interface SearchItem {
  id: number
  name: string
  text: string
  category: string
}

interface Category {
  name: string
  icon: string
  data: Array<SearchItem>
}

interface SendType {
  total: number
  lastStatus: string
  errorMessage: string
}

interface Delivery {
  mail: SendType
  fax: SendType
  data: SendType
  download: SendType
}

interface Invoice {
  amount: number
  boxes: number
  stems: number
  customerCode: string
  customerId: number
  customerName: string
  d1: Delivery
  d2: Delivery
  invoiceMethod: Array<string> | null
  remark: string | null
}

const InvoicePage = defineComponent({
  components: {
    StepButton,
    LoaderComponent,
    AddButton,
    CustomerDialog,
    ConfirmZipOrPdfDialog,
    ConfirmSendInvoice,
    CommentDialog,
    EditSendTos,
    PreviewInvoiceDialog
  },
  setup(props, { root }) {
    // Status send invoice
    const FAILED = 'failed'
    const SUCCESS = 'success'
    const SENDING = 'sending'
    const { $toast, $router, $route, $store } = root
    const TYPE_ACTION = {
      SEND_D1: 'd1',
      SEND_D2: 'd2',
      DOWNLOAD_PDF: 'download_pdf'
    }
    const currentTypeAction = ref<string>(TYPE_ACTION.SEND_D1)
    const auctionDateModal = ref(false)
    const date = ref<string>(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.aucDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const searchInput = ref('')
    const invoices = ref<Array<Invoice>>([])
    const filteredInvoices = ref<Array<Invoice>>([])
    const onSearching = ref(false)
    const listSearch = ref<SearchItem[]>([])
    const selectedList = ref<SearchItem[]>([])
    const loadingListSearch = ref(false)
    const loadingInvoiceList = ref(false)
    const categoriesList = ref<Category[]>([])
    const showBottomSheet = ref<boolean>(false)
    const isSingleInvoice = ref<boolean>(false)
    const showConfirmSendInvoice = ref(false)
    const isCallingApi = ref<boolean>(false)
    const comment = ref<string>('')
    const showEditSendTosDialog = ref(false)
    const isShowErrorMess = ref(false)
    const errorMess = ref('')
    const showEditCustomerDialog = ref(false)
    const customerEdit = ref({})
    const showInvoiceDetail = ref(false)
    const remarkInvoiceSelected = ref('')
    const itemsHasOrderNum: Ref<Array<Item>> = ref([])
    const varietiesHasOrderNum: Ref<Array<Variety>> = ref([])
    const customersHasOrderNum: Ref<Array<CustomerRaw>> = ref([])
    const isCalledDataHasOrderNum = ref(false)
    const newKey = ref(1)
    // sent method
    const ALL = 'ALL'
    const MAIL_ONLY = 'MAIL ONLY'
    const FAX_ONLY = 'FAX ONLY'
    // const UPDATE_ONLY = 'UPDATE ONLY'
    const DELIVERY_FAILED_ONLY = 'DELIVERY FAILED ONLY'
    const SEND_D1_INVOICE = 'SENT D1 INVOICE'
    const SEND_D2_INVOICE = 'SEND D2 INVOICE'
    const selectedMethod = ref(ALL)
    const sentMethod = ref([
      { name: root.$t('invoice.all'), key: ALL },
      { name: root.$t('invoice.mail_only'), key: MAIL_ONLY },
      { name: root.$t('invoice.fax_only'), key: FAX_ONLY },
      // TODO: filter invoices that have been updated assignments
      // { name: root.$t('invoice.update_only'), key: UPDATE_ONLY },
      { name: root.$t('invoice.delivery_failed_only'), key: DELIVERY_FAILED_ONLY },
      { name: root.$t('invoice.sent_d1_invoice'), key: SEND_D1_INVOICE },
      { name: root.$t('invoice.sent_d2_invoice'), key: SEND_D2_INVOICE }
    ])
    const customerIdSelected = ref<number>(0)
    const nextAuctionDate = ref('')
    const isOpenZipOrPdfDialog = ref(false)
    const isOpenCommentDialog = ref(false)
    const remarkSelected = ref('')
    const itemsSelected = ref<number[]>([])
    const varietiesSelected = ref<number[]>([])
    const customersSelected = ref<number[]>([])

    const getCustomerEdit = async () => {
      showBottomSheet.value = false
      isCallingApi.value = true
      try {
        const { data } = await api.get(`${endpoints.CUSTOMERS}${customerIdSelected.value}`)
        customerEdit.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        showEditCustomerDialog.value = true
        isCallingApi.value = false
      }
    }

    const filterInvoices = () => {
      switch (selectedMethod.value) {
        case ALL:
          filteredInvoices.value = invoices.value
          break
        case MAIL_ONLY:
          filteredInvoices.value = invoices.value.filter(
            (e: Invoice) => e.d1.mail.total || e.d2.mail.total
          )
          break
        case FAX_ONLY:
          filteredInvoices.value = invoices.value.filter(
            (e: Invoice) => e.d1.fax.total || e.d2.fax.total
          )
          break
        case DELIVERY_FAILED_ONLY:
          filteredInvoices.value = invoices.value.filter((e: Invoice) => {
            let result = false
            if (
              e.d1.mail.lastStatus === FAILED ||
              e.d1.data.lastStatus === FAILED ||
              e.d1.fax.lastStatus === FAILED ||
              e.d1.download.lastStatus === FAILED
            ) {
              result = true
            }
            if (
              e.d2.mail.lastStatus === FAILED ||
              e.d2.data.lastStatus === FAILED ||
              e.d2.fax.lastStatus === FAILED ||
              e.d2.download.lastStatus === FAILED
            ) {
              result = true
            }
            return result
          })
          break
        case SEND_D1_INVOICE:
          filteredInvoices.value = invoices.value.filter(
            (e: Invoice) =>
              e.d1.mail.total || e.d1.fax.total || e.d1.data.total || e.d1.download.total
          )
          break
        case SEND_D2_INVOICE:
          filteredInvoices.value = invoices.value.filter(
            (e: Invoice) =>
              e.d2.mail.total || e.d2.fax.total || e.d2.data.total || e.d2.download.total
          )
          break
        default:
          filteredInvoices.value = invoices.value
      }
    }

    const getListSearch = async (input?: string): Promise<void> => {
      newKey.value += 1
      let itemAPI = `${endpoints.ITEMS}has_order_num`
      let varietyAPI = `${endpoints.VARIETIES}has_order_num`
      let customerAPI = `${endpoints.CUSTOMERS}has_order_num`
      if (input) {
        const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
        itemAPI = `${endpoints.ITEMS}search_with?search_input=${inputValue}`
        varietyAPI = `${endpoints.VARIETIES}search_with?search_input=${inputValue}`
        customerAPI = `${endpoints.CUSTOMERS}search_with?search_input=${inputValue}`
      }
      listSearch.value = [...selectedList.value]
      loadingListSearch.value = true
      try {
        categoriesList.value = [
          { name: 'item', icon: 'mdi-flower', data: [] },
          { name: 'variety', icon: 'mdi-flower', data: [] },
          { name: 'customer', icon: 'mdi-home', data: [] }
        ]
        let responseItems = null
        let responseVarieties = null
        let responseCustomers = null
        if (!isCalledDataHasOrderNum.value || input) {
          responseItems = await api.get(itemAPI)
          responseVarieties = await api.get(varietyAPI)
          responseCustomers = await api.get(customerAPI)
          if (!isCalledDataHasOrderNum.value) {
            isCalledDataHasOrderNum.value = true
            itemsHasOrderNum.value = toCamelCase(responseItems?.data)
            varietiesHasOrderNum.value = toCamelCase(responseVarieties?.data)
            customersHasOrderNum.value = toCamelCase(responseCustomers?.data)
          }
        }
        const itemsList = input ? toCamelCase(responseItems?.data) : itemsHasOrderNum.value
        categoriesList.value.push()
        itemsList.forEach((item: Item) => {
          const newItem = {
            id: item.id,
            name: item.name,
            text: item.name,
            category: 'item'
          }
          let searchResult: any = null
          const isExist = selectedList.value.find(
            (e) => e.category === 'item' && e.id === newItem.id
          )
          const categoryItemList = categoriesList.value.find((e) => e.name === 'item')?.data
          const isExistItem = categoryItemList?.find((e) => e.id === newItem.id)
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = item.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.unshift(newItem)
              }
            } else if (item.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newItem)
            }
            if (!isExistItem) {
              categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
            }
          }
        })
        const varietiesList = input
          ? toCamelCase(responseVarieties?.data)
          : varietiesHasOrderNum.value
        varietiesList.forEach((variety: Variety) => {
          const newVariety = {
            id: variety.id,
            name: variety.name,
            text: `${variety.item.name} ${variety.name}`,
            category: 'variety'
          }
          const isExist = selectedList.value.find(
            (e) => e.category === 'variety' && e.id === newVariety.id
          )
          const categoryVarietyList = categoriesList.value.find((e) => e.name === 'variety')?.data
          const isExistVariety = categoryVarietyList?.find((e) => e.id === newVariety.id)
          let searchResult: any = null
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = variety.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist && searchResult) {
                listSearch.value.unshift(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.unshift(newVariety)
              }
            } else if (variety.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
              }
            }
          } else {
            if (!isExist && searchResult) {
              listSearch.value.push(newVariety)
            }
            if (!isExistVariety) {
              categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
            }
          }
        })
        const customersList = input
          ? toCamelCase(responseCustomers?.data)
          : customersHasOrderNum.value
        customersList.forEach((customer: CustomerRaw) => {
          const newCustomer = {
            id: customer.id,
            name: customer.shortName,
            text: `${customer.code ? customer.code : ''} ${customer.shortName}`,
            category: 'customer'
          }
          const isExist = selectedList.value.find((e: any) => {
            return e.category === 'customer' && e.id === newCustomer.id
          })
          const categoryCustomerList = categoriesList.value.find((e) => e.name === 'customer')?.data
          const isExistCustomer = categoryCustomerList?.find((e) => e.id === newCustomer.id)
          let searchResult: any = null
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = customer.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newCustomer)
              }
              if (!isExistCustomer) {
                categoriesList.value.find((e) => e.name === 'customer')?.data.unshift(newCustomer)
              }
            } else if (customer.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newCustomer)
              }
              if (!isExistCustomer) {
                categoriesList.value.find((e) => e.name === 'customer')?.data.push(newCustomer)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newCustomer)
            }
            if (!isExistCustomer) {
              categoriesList.value.find((e) => e.name === 'customer')?.data.push(newCustomer)
            }
          }
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loadingListSearch.value = false
      }
    }

    const updateRemarkAll = () => {
      $toast.info(root.$t('invoice.update_to_all_remark'))
      invoices.value = invoices.value.map((invoice) => {
        /* eslint-disable no-param-reassign */
        invoice.remark = comment.value
        return invoice
      })
      filterInvoices()
    }

    const updateRemark = (changedRemark: string) => {
      invoices.value.forEach((e) => {
        if (e.customerId === customerIdSelected.value) {
          e.remark = changedRemark
        }
      })
      remarkInvoiceSelected.value = changedRemark
      filterInvoices()
      isOpenCommentDialog.value = false
    }

    const getData = async () => {
      const queryData = query.buildQuery({
        auction_date: date.value,
        item_ids: itemsSelected.value.length > 0 ? itemsSelected.value.toString() : '',
        variety_ids: varietiesSelected.value.length > 0 ? varietiesSelected.value.toString() : ''
      })

      const { data } = await api.get(`${endpoints.INVOICES}?${queryData}`)
      invoices.value = toCamelCase(data)
      filterInvoices()
      // filter by customer selected
      if (customersSelected.value.length > 0) {
        filteredInvoices.value = filteredInvoices.value.filter((invoice: Invoice) => {
          return customersSelected.value.indexOf(invoice.customerId) > -1
        })
      }
    }

    const sendInvoices = async (invoiceType: string, deliveriesSingleCustomer: any[] = []) => {
      showBottomSheet.value = false

      showConfirmSendInvoice.value = false
      const multipleCustomer = filteredInvoices.value.map((e: Invoice) => {
        const deliveries: Array<any> = []
        e.invoiceMethod?.forEach((method: string) => {
          // Should not send the success again
          if (invoiceType === 'd1') {
            if (e.d1[method as keyof Delivery].lastStatus !== SUCCESS) {
              deliveries.push({ send_type: method })
            }
          } else if (invoiceType === 'd2') {
            if (e.d2[method as keyof Delivery].lastStatus !== SUCCESS) {
              deliveries.push({ send_type: method })
            }
          }
        })

        return {
          customer_id: e.customerId,
          auction_date: date.value,
          remark: e.remark,
          deliveries: invoiceType === 'd1' ? { d1: deliveries || [] } : { d2: deliveries || [] }
        }
      })
      // send singleCustomer
      const selectedCustomer = invoices.value.find((e) => e.customerId === customerIdSelected.value)
      const singleCustomer = [
        {
          customer_id: customerIdSelected.value,
          auction_date: date.value,
          remark: selectedCustomer?.remark,
          deliveries:
            invoiceType === 'd1'
              ? { d1: deliveriesSingleCustomer || [] }
              : { d2: deliveriesSingleCustomer || [] }
        }
      ]
      // Don't call send_invoices if send_type is null
      if (isSingleInvoice.value && deliveriesSingleCustomer?.length === 0) {
        $toast.warning(root.$t('invoice.msg.none_send_type'))
        return
      }
      const param = isSingleInvoice.value ? singleCustomer : multipleCustomer
      if (param.length === 0) {
        $toast.warning(root.$t('invoice.msg.invoice_list_empty'))
        return
      }
      try {
        isCallingApi.value = true
        const { data } = await api.post(
          `${endpoints.INVOICES}send_invoices?is_single=${isSingleInvoice.value}`,
          param
        )
        $toast.success(root.$t('invoice.msg.invoice_sending'))
        const urlPdf = data?.url
        if (urlPdf) {
          window.open(urlPdf)
        }
      } catch (e) {
        showError(e, $toast, root.$t('invoice.msg.send_invoice_failed') as string)
      } finally {
        isCallingApi.value = false
        getData()
      }
    }

    const onClickToShowSendGenerationDialog = (typeAction: string): void => {
      currentTypeAction.value = typeAction
      if (!isSingleInvoice.value) {
        showConfirmSendInvoice.value = true
      } else {
        showEditSendTosDialog.value = true
      }
      showBottomSheet.value = false
    }

    const closeSearch = () => {
      setTimeout(() => {
        onSearching.value = false
      }, 300)
    }

    const clearSearchValue = () => {
      searchInput.value = ''
      selectedList.value = []
      getListSearch()
    }

    const onClickDetailInvoice = (type: string, customerId?: number): void => {
      showBottomSheet.value = !showBottomSheet.value
      if (customerId) {
        customerIdSelected.value = customerId
        const selectedCustomer = invoices.value.find(
          (e) => e.customerId === customerIdSelected.value
        )
        remarkSelected.value = selectedCustomer?.remark || ''
      }
      isSingleInvoice.value = type === 'single'
    }

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        date.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const goToDate = async () => {
      loadingInvoiceList.value = true
      $router
        .replace({
          name: urlPath.INVOICE.name,
          query: {
            aucDate: date.value,
            called: 'true'
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })
      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: $store.state.common.latestSelectedDates.packingDateRange
          ? $store.state.common.latestSelectedDates.packingDateRange
          : [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)

      await getData()
      loadingInvoiceList.value = false
    }

    const updateCagoriesList = debounce(async (input: string) => {
      if (input && input !== searchInput.value) {
        searchInput.value = input
        getListSearch(input)
      }
    }, 300)

    const handleDownLoadPdf = async (isZip: boolean, invoiceType: string) => {
      const selectedCustomer = invoices.value.find((e) => e.customerId === customerIdSelected.value)
      const queryData = {
        detail_customers: isSingleInvoice.value
          ? [{ id: customerIdSelected.value, remark: selectedCustomer?.remark }]
          : filteredInvoices.value.map((e) => ({ id: e.customerId, remark: e.remark })),
        auction_date: date.value,
        is_compress: isZip,
        invoice_type: invoiceType
      }
      if (queryData.detail_customers.length === 0) {
        $toast.warning(root.$t('invoice.msg.invoice_list_empty'))
        return
      }
      try {
        isCallingApi.value = true
        const { data } = await api.post(`${endpoints.INVOICES}pdf`, queryData)
        const urlPdf = toCamelCase(data).url
        window.open(urlPdf)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        isCallingApi.value = false
      }
    }

    const handleOnOk = (data: any) => {
      const isCompress = isSingleInvoice.value ? false : data.fileType === 'zip'
      showBottomSheet.value = false
      handleDownLoadPdf(isCompress, data.invoiceType)
      isOpenZipOrPdfDialog.value = false
    }

    const goToInvoiceDetail = (customerId: number) => {
      customerIdSelected.value = customerId
      remarkInvoiceSelected.value =
        invoices.value.find((e) => e.customerId === customerId)?.remark || ''
      showInvoiceDetail.value = true
    }
    const getColorIcon = (status: string) => {
      let color = 'grey'
      if (status === SENDING) {
        color = 'orange'
      } else if (status === SUCCESS) {
        color = 'green'
      } else if (status === FAILED) {
        color = 'red'
      }
      return color
    }
    const getClassCountDelivery = (status: string) => {
      let className = 'grey--text'
      if (status === SENDING) {
        className = 'orange--text'
      } else if (status === SUCCESS) {
        className = 'green--text'
      } else if (status === FAILED) {
        className = 'red--text'
      }
      return className
    }

    const showStatus = (name: string, defaultType: string[], count: number) => {
      let result = false
      if (defaultType) {
        result = Boolean(defaultType.includes(name) || count)
      } else {
        result = Boolean(count)
      }
      return result
    }

    const showErrorMess = (status: string, message: string | null) => {
      if (status === FAILED) {
        errorMess.value = message || ''
        isShowErrorMess.value = true
      }
    }

    const filterAssigns = async () => {
      itemsSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'item'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      varietiesSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'variety'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      customersSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'customer'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      await getData()
    }

    watch(selectedList, () => {
      searchInput.value = ''
      filterAssigns()
    })

    watch(onSearching, (val) => {
      searchInput.value = ''
      if (val) {
        getListSearch()
      }
    })

    onMounted(async () => {
      loadingInvoiceList.value = true
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        )
      )
        await getNextAuctionDate()
      await getData()
      loadingInvoiceList.value = false
    })

    return {
      TYPE_ACTION,
      currentTypeAction,
      date,
      auctionDateModal,
      showConfirmSendInvoice,
      comment,
      goToDate,
      sentMethod,
      searchInput,
      selectedMethod,
      invoices,
      closeSearch,
      listSearch,
      clearSearchValue,
      updateCagoriesList,
      selectedList,
      onSearching,
      loadingListSearch,
      loadingInvoiceList,
      categoriesList,
      showBottomSheet,
      isSingleInvoice,
      showEditSendTosDialog,
      isShowErrorMess,
      errorMess,
      showInvoiceDetail,
      showErrorMess,
      onClickDetailInvoice,
      onClickToShowSendGenerationDialog,
      handleDownLoadPdf,
      isCallingApi,
      goToInvoiceDetail,
      isOpenZipOrPdfDialog,
      handleOnOk,
      sendInvoices,
      getColorIcon,
      getClassCountDelivery,
      showStatus,
      updateRemarkAll,
      getData,
      isOpenCommentDialog,
      customerIdSelected,
      remarkSelected,
      updateRemark,
      showEditCustomerDialog,
      customerEdit,
      getCustomerEdit,
      remarkInvoiceSelected,
      filteredInvoices,
      filterInvoices,
      itemsSelected,
      varietiesSelected,
      newKey
    }
  }
})

export default InvoicePage
